#sidebar {
    margin:10px;
    text-align:center;
    width: 180px;
}

#sidebar ul {
    list-style-type:none;
    padding:0;
}

#sidebar li {
    border:1px solid black;
    padding:10px;
}

.add-folder, .go-back {
    background:white;
    border:1px solid black;
    width:160px;
    padding:10px;
}

.folder:hover {
    background:skyblue;
}

.active {
    background:skyblue;
}

.foldericon {
    height:15px;
}

.noteicon {
    height:15px;
}

.add-note {
    margin-top:10px;
}

button:hover {
    background:skyblue;
}