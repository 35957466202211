ul {
    list-style-type:none;
    padding:0;
}

.note {
    border: 1px solid black;
    padding:10px;
    position:relative;
    margin-bottom:10px;
    margin-right:10px;
}

.delete {
    position:absolute;
    background:white;
    border:1px solid black;
    width:160px;
    padding:10px;
    bottom:10px;
    right:10px;
}

.note-content {
    padding:10px;
}