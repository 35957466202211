.notelist ul {
    list-style-type:none;
    padding:0;
}

.add-note {
    background:white;
    border:1px solid black;
    width:160px;
    padding:10px;
}

.note {
    border: 1px solid black;
    padding:10px;
    position:relative;
    margin-bottom:10px;
}

.delete {
    position:absolute;
    background:white;
    border:1px solid black;
    width:160px;
    top: 10px;
    right: 10px;
    height: 40px;
}

.clear-filter {
    margin-top:10px;
}